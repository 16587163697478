exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bestseller-js": () => import("./../../../src/pages/bestseller.js" /* webpackChunkName: "component---src-pages-bestseller-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-specials-js": () => import("./../../../src/pages/specials.js" /* webpackChunkName: "component---src-pages-specials-js" */),
  "component---src-pages-staffpicks-js": () => import("./../../../src/pages/staffpicks.js" /* webpackChunkName: "component---src-pages-staffpicks-js" */),
  "component---src-pages-strapi-category-slug-js": () => import("./../../../src/pages/{strapiCategory.slug}.js" /* webpackChunkName: "component---src-pages-strapi-category-slug-js" */),
  "component---src-pages-strapi-liquor-slug-js": () => import("./../../../src/pages/{strapiLiquor.slug}.js" /* webpackChunkName: "component---src-pages-strapi-liquor-slug-js" */),
  "component---src-pages-strapi-wine-slug-js": () => import("./../../../src/pages/{strapiWine.slug}.js" /* webpackChunkName: "component---src-pages-strapi-wine-slug-js" */),
  "component---src-pages-toprated-js": () => import("./../../../src/pages/toprated.js" /* webpackChunkName: "component---src-pages-toprated-js" */),
  "component---src-pages-trending-js": () => import("./../../../src/pages/trending.js" /* webpackChunkName: "component---src-pages-trending-js" */)
}

